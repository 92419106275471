import { sendGet, sendPost, sendPut } from '../../utils/api'

const state = {}

const actions = {
  fetchAddOnList ({ commit }, { offset, limit, isActive, isParent }) {
    return new Promise((resolve, reject) => {
      sendGet('/addOn/list', { offset, limit, isActive, isParent })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createAddOn ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/addOn', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateAddOn ({ commit }, { id, ...payload }) {
    return new Promise((resolve, reject) => {
      sendPut(`/addOn/${id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
