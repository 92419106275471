import { sendGet } from '../../utils/api'
import clone from 'lodash/clone'

const state = {
  searchAddressResult: [],
  searchInterfaceData: {
    street: '',
    city: '',
    number: '',
  },
  selectedStokabPoint: null,
}

const actions = {
  getAvailabilityByAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/stokab/getAvailabilityByAddress', payload)
        .then((response) => {
          commit('SET_SEARCH_RESULT', response)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getStokabNetworks ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/stokab/networks', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setSearchInterfaceFormData ({ commit }, payload) {
    commit('SET_SEARCH_INTERFACE_FORM_DATA', payload)
  },

  setSearchAddressResult ({ commit }, address) {
    commit('SET_SEARCH_RESULT', address)
  },

  setSelectedStokabPoint ({ commit }, pointItem) {
    commit('SET_SELECTED_STOKAB_POINT', pointItem)
  },
}

const getters = {
  getSearchInterfaceData: (state) => state.searchInterfaceData,
  getSearchAddressResult: (state) => state.searchAddressResult,
  getSelectedStokabPoint: (state) => state.selectedStokabPoint,
}

const mutations = {
  SET_SEARCH_INTERFACE_FORM_DATA (state, payload) {
    state.searchInterfaceData = {
      city: payload.city,
      street: payload.street,
      number: payload.number,
    }
  },

  SET_SEARCH_RESULT (state, payload) {
    state.searchAddressResult = clone(payload)
  },

  SET_SELECTED_STOKAB_POINT (state, payload) {
    state.selectedStokabPoint = clone(payload)
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
