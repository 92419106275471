import { sendPost, sendGet, sendPut } from '../../utils/api'

const state = {}

const actions = {
  fetchExternalDomainList ({ commit }, { offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/external-domain/list', { offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  creatExternalDomain ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/external-domain', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateExternalDomain ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/external-domain', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
