import { sendGet } from '../../utils/api'

const state = {}

const actions = {
  getSuggestionAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/address/autocomplete', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchSearchedAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/address/searched_address', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getValidAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/address/valid_address', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
