import { sendGet, sendPut } from '../../utils/api'

const state = {}

const actions = {
  loadConsumptionMonthlyDeviceIds ({ commit }, month) {
    return new Promise((resolve, reject) => {
      sendGet('/consumption/monthly-devices', { month })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  loadMonthlyConsumptionData ({ commit }, month) {
    return new Promise((resolve, reject) => {
      sendGet('/consumption/monthly', { month })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  loadDailyConsumptionData ({ commit }, { date, deviceId, offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/consumption/daily', { date, deviceId, offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getConsumptionDevices ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/consumption/devices', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateConsumptionDevice ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/consumption/device', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
