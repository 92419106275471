import {
  sendDelete,
  sendGet,
  sendPut,
  sendPost,
} from '../../utils/api'

const state = {
  currentUser: {},
  isAdminUser: false,
  isPartnerAdminUser: false,
  isCustomer: false,
}

const actions = {
  login ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/auth', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  register ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/register', payload)
        .then((response) => {
          resolve(response.token)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  sendForgotPasswordEmail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/forget_password', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  resetPassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/reset_password', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  me ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/user')
        .then((response) => {
          commit('SET_CURRENT_USER', response.user)
          commit('SET_ADMIN_USER', response.user.role.includes('admin'))
          commit(
            'SET_PARTNER_ADMIN_USER',
            response.user.role.includes('partnerAdmin'),
          )
          resolve(response.user)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateUserProfile ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/user', payload)
        .then((response) => {
          commit('SET_CURRENT_USER', response)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchUsers ({ commit }, { offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/user/list', { offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  closeAccount ({ commit }) {
    return new Promise((resolve, reject) => {
      sendDelete('/user')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createCustomersWithOrder ({ commit }) {
    return new Promise((resolve, reject) => {
      sendPost('/create_customers_with_order')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updatePassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/update_password', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {
  getCurrentUser: (state) => state.currentUser,
  isAdminUser: (state) => state.isAdminUser,
  isPartnerAdminUser: (state) => state.isPartnerAdminUser,
  isCustomer: (state) => (state.currentUser?.role || '').includes('user'),
}

const mutations = {
  SET_CURRENT_USER (state, payload) {
    state.currentUser = payload
  },
  SET_ADMIN_USER (state, payload) {
    state.isAdminUser = payload
  },
  SET_PARTNER_ADMIN_USER (state, payload) {
    state.isPartnerAdminUser = payload
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
