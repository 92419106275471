import {
  sendGet,
  sendPut,
  sendPost,
  sendDelete,
} from '../../utils/api'
import clone from 'lodash/clone'

const state = {
  address: null,
  networkInfo: null,
  stokabProduct: null,
  manualOfferInquiry: null,
  availableService: null,
  selectedAccess: null,
}

const actions = {
  addStokabPrice ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/stokab_price', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addRequestPrice ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/request_price', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchRequestPrice ({ commit }, requestId) {
    return new Promise((resolve, reject) => {
      sendGet(`/request_price/${requestId}`)
        .then((response) => {
          resolve(response.requestPrice)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchPriceRequestList ({ commit }, { offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/request_price', { offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  replyPriceRequest ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/reply_request_price', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchQuotesList ({ commit }, { offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/quotes', { offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  signWithBankId ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/sign_bank_id', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/order_service', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchOrdersList ({ commit }, query) {
    return new Promise((resolve, reject) => {
      sendGet('/order/list', query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchOrderInfo ({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      sendGet(`/order/${orderId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  approveOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/order/approve', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  rescanOrderInfo ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/order/rescan', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getSubscriptionCustomer ({ commit }, socialSecurityNumber) {
    return new Promise((resolve, reject) => {
      sendGet(`/order/customer?socialSecurityNumber=${socialSecurityNumber}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createOrderSubscription ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/subscription', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  retryOrderInfo ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/retry', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  markDeliveredOrderInfo ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/order/mark-delivered', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  resendOrderConfirmationEmail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/resend-confirmation-email', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadOrder ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/order/download')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setServiceAddress ({ commit }, address) {
    commit('SET_SERVICE_ADDRESS', address)
  },

  setNetworkInfo ({ commit }, networkInfo) {
    commit('SET_NETWORK_INFO', networkInfo)
  },

  setStokabProductInfo ({ commit }, stokabProduct) {
    commit('SET_STOKAB_PRODUCT_INFO', stokabProduct)
  },

  setAvailableService ({ commit }, availableService) {
    commit('SET_AVAILABLE_SERVICE', availableService)
  },

  setSelectedAccess ({ commit }, access) {
    commit('SET_SELECTED_ACCESS', access)
  },

  getOrderStatistics ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/order/statistics')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setManualOfferInquiry ({ commit }, offerInquiry) {
    commit('SET_MANUAL_OFFER_INQUIRY', offerInquiry)
  },

  fetchIpOnlyOrders ({ commit }, query) {
    return new Promise((resolve, reject) => {
      sendGet('/ipOnly/web-orders', query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchZitiusOrders ({ commit }, query) {
    return new Promise((resolve, reject) => {
      sendGet('/zitius/web-orders', query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchParkedZitiusOrders ({ commit }, query) {
    return new Promise((resolve, reject) => {
      sendGet('/zitius/parked-web-orders', query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchIpOnlyOrder ({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      sendGet(`/ipOnly/order/${orderId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createOrderFromWebOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/web-order', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  cancelOrder ({ commit }, id) {
    return new Promise((resolve, reject) => {
      sendDelete(`/order/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addPersonalData ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/order/add-personal-data', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateOrderInfo ({ commit }, { id, ...payload }) {
    return new Promise((resolve, reject) => {
      sendPut(`/order/${id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {
  getServiceAddress: state => state.address,
  getNetworkInfo: state => state.networkInfo,
  getStokabProductInfo: state => state.stokabProduct,
  getManualOfferInquiry: state => state.manualOfferInquiry,
  getAvailableService: state => state.availableService,
  getSelectedAccess: state => state.selectedAccess,
}

const mutations = {
  SET_SERVICE_ADDRESS (state, payload) {
    state.address = clone(payload)
  },

  SET_NETWORK_INFO (state, payload) {
    state.networkInfo = clone(payload)
  },

  SET_STOKAB_PRODUCT_INFO (state, payload) {
    state.stokabProduct = clone(payload)
  },

  SET_MANUAL_OFFER_INQUIRY (state, payload) {
    state.manualOfferInquiry = clone(payload)
  },

  SET_AVAILABLE_SERVICE (state, payload) {
    state.availableService = clone(payload)
  },

  SET_SELECTED_ACCESS (state, payload) {
    state.selectedAccess = clone(payload)
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
