import {
  sendGet,
  sendPost,
  sendDelete,
} from '../../utils/api'

const state = {}

const actions = {
  createManualOffer ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/manualOffer/create', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchManualOfferList ({ commit }, { userId, status, offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/manualOffer/list', { userId, status, offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  signWithBankIdForManualOffer ({ commit }, { id, personalNumber, offerInfo }) {
    return new Promise((resolve, reject) => {
      sendPost(`/manualOffer/${id}/sign_bank_id`, {
        personalNumber,
        offerInfo,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  acceptManualOffer ({ commit }, { id, sessionId }) {
    return new Promise((resolve, reject) => {
      sendPost(`/manualOffer/${id}/accept`, {
        sessionId,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  rejectManualOffer ({ commit }, { id, rejectReason }) {
    return new Promise((resolve, reject) => {
      sendPost(`/manualOffer/${id}/reject`, {
        reason: rejectReason,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteManualOffer ({ commit }, id) {
    return new Promise((resolve, reject) => {
      sendDelete(`/manualOffer/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
