import { sendGet, sendPost } from '../../utils/api'

const state = {}

const actions = {
  getLastUpdatedDate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/lastUpdated', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAccessesByAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/getAccessesByAddress', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateAccesses ({ commit }, type) {
    return new Promise((resolve, reject) => {
      sendPost(`/openNetwork/updateAccesses?type=${type}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchReportData ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/reports')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAccessDetail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/access', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAccessAndProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/products', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateAccessInfo ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/openNetwork/access', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateAccessAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/openNetwork/address', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchIPOnlyReportData ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/ipOnly/reports')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchOpenNetworkSubscriptions ({ commit }, query) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/subscriptions', query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchCustomerBillingInfo ({ commit }, query) {
    return new Promise((resolve, reject) => {
      sendGet('/openNetwork/billing', query)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createLinkBetweenSubscriptionAndBilling ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/openNetwork/link-subscription-billing', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
