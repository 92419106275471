import moment from 'moment-timezone'

const apiURL = `${process.env.VUE_APP_SERVER_URL}/api/`
const publicURL = process.env.VUE_APP_SERVER_URL

const getErrorResponse = (error) => {
  if (error?.response?.status === 401) {
    localStorage.clear()
    window.location.href = '/'

    return error?.response?.data?.message
  }

  if (error?.response?.data?.message) {
    return error.response.data.message
  } else if (error?.response?.data) {
    return error.response.data
  } else {
    return error?.message
  }
}

const getWebsiteUrl = () => {
  const hostName = window.location.hostname
  const pathName = window.location.pathname
  const protocol = window.location.protocol
  const port = window.location.port

  return `${protocol}//${hostName}:${port}${pathName}`
}

// format date with defined format and timezone
const formatDateTime = (date, format = 'MMM DD, YYYY') => {
  if (!date) {
    return
  }

  // format date time with local timezone
  return moment(date).format(format)
}

const removeAllSpaces = (value) => {
  return value ? value.replace(/ /g, '') : ''
}

const convertTextToHtml = (text) => {
  const regReplaceForSpace = new RegExp(' ', 'g')
  let htmlText = text ? text.replace(regReplaceForSpace, '&nbsp;') : ''

  const regReplaceForBreakLine = new RegExp('\\n', 'g')
  htmlText = htmlText.replace(regReplaceForBreakLine, '<br />')

  return htmlText
}

const getFullAddress = (address) => {
  let fullAddress = `${address.street} ${address.street_number}`

  if (address.letter) {
    fullAddress = `${fullAddress} ${address.letter}`
  }

  fullAddress = `${fullAddress}, ${formatPostalCode(address.postalcode)} ${
    address.locality
  }`

  return fullAddress
}

const isSuccessInquiry = (offerInquiryProducts) => {
  if (offerInquiryProducts.length === 0) {
    return true
  }

  let flag = true
  for (const product of offerInquiryProducts) {
    if (product.status.state === 'WAIT_ASYNC_ANSWER') {
      flag = false
    }
  }

  return flag
}

const formatPostalCode = (postalCode) => {
  if (!postalCode) {
    return ''
  }

  const cleanedPostalCode = removeAllSpaces(postalCode)

  if (cleanedPostalCode.length === 5) {
    const formattedPostalCode = cleanedPostalCode.slice(0, 3) + ' ' + cleanedPostalCode.slice(3)
    return formattedPostalCode
  } else {
    // Handle invalid input
    return ''
  }
}

const getStokabTypeName = (type) => {
  const relatedAnswerList = {
    5: 'Residential Housenode',
    12: 'Traditional ODF',
    14: 'Kommercial Housenode',
  }

  return relatedAnswerList[type] || 'N/A'
}

export default {
  apiURL,
  publicURL,
  getErrorResponse,
  getWebsiteUrl,

  formatDateTime,
  getFullAddress,
  convertTextToHtml,
  isSuccessInquiry,
  getStokabTypeName,
  formatPostalCode,
}
