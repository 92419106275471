import { themeConfig } from '@/themeConfig'

const state = {
  themeMode: themeConfig,
}

const getters = {
  getThemeMode: state => state.themeMode,
}

const actions = {
  changeVerticalSidebarDrawer ({ commit }) {
    commit('toggleVerticalSidebarDrawer')
  },

  changeThemeLoadingState ({ commit }, payload) {
    commit('toggleThemeLoadingState', payload)
  },
}

const mutations = {
  toggleThemeLoadingState (state, payload) {
    state.themeMode.isLoading = payload
  },
  toggleVerticalSidebarDrawer (state) {
    state.themeMode.verticalSidebarDrawer = !state.themeMode
      .verticalSidebarDrawer
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
