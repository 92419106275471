const setLoading = ({ commit }, payload) => {
  commit('SET_LOADING', payload)
}

const setLoadingText = ({ commit }, payload) => {
  commit('SET_LOADING_TEXT', payload)
}

export { setLoading }
export { setLoadingText }
