import {
  sendGet,
  sendPut,
  sendPost,
  sendDelete,
} from '../../utils/api'
import clone from 'lodash/clone'

const state = {
  filterCondition: null,
}

const actions = {
  fetchProduct ({ commit }, productId) {
    return new Promise((resolve, reject) => {
      sendGet(`/product/${productId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchProductList ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/product/list')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/product', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateProduct ({ commit }, { id, ...payload }) {
    return new Promise((resolve, reject) => {
      sendPut(`/product/${id}`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateProductAddons ({ commit }, { id, addOnIds }) {
    return new Promise((resolve, reject) => {
      sendPut(`/product/${id}/update-addons`, { addOnIds })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteProductAddOn ({ commit }, { id, addOnId }) {
    return new Promise((resolve, reject) => {
      sendDelete(`/product/${id}/delete-addon`, { addOnId })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setFilterCondition ({ commit }, filterCondition) {
    commit('SET_FILTER_CONDITION', filterCondition)
  },
}

const getters = {
  getFilterCondition: state => state.filterCondition,
}

const mutations = {
  SET_FILTER_CONDITION (state, payload) {
    state.filterCondition = clone(payload)
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
