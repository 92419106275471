import { sendGet, sendPut } from '../../utils/api'

const state = {}

const actions = {
  getZitiusToken ({ commit }) {
    return new Promise((resolve, reject) => {
      sendGet('/zitius/token')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateZitiusToken ({ commit }, token) {
    return new Promise((resolve, reject) => {
      sendPut('/zitius/token', {
        token,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
