// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/app/public/Index'),
      redirect: '/',
      children: [
        {
          path: '/',
          component: () => import('@/views/home/Index'),
        },
        {
          path: '/signin',
          component: () => import('@/views/auth/SignIn'),
        },
        {
          path: '/signup',
          component: () => import('@/views/auth/SignUp'),
        },
        {
          path: '/forgot-password',
          component: () => import('@/views/auth/ForgotPassword'),
        },
        {
          path: '/reset/:token?',
          component: () => import('@/views/auth/ResetPassword'),
        },
        {
          path: '/partner-register/:token?',
          component: () => import('@/views/auth/PartnerRegister'),
        },
        {
          path: '/search',
          name: 'NewSearch',
          component: () => import('@/views/new-order/Search'),
        },
        {
          path: '/commercial-search',
          name: 'CommercialSearch',
          component: () => import('@/views/commercial-order/Search'),
        },
        {
          path: '/order',
          name: 'PublicOrderProduct',
          component: () => import('@/views/order/Order'),
          meta: {
            nameKey: 'order',
          },
        },
        {
          path: '/status',
          component: () => import('@/views/reports/Reports'),
        },
        {
          path: '/status/embed',
          name: 'EmbedStatus',
          component: () => import('@/views/reports/Reports'),
        },
        {
          path: '/ip-only-status',
          component: () => import('@/views/reports/IPOnlyReports'),
        },
        {
          path: '/ip-only-status/embed',
          name: 'IPOnlyEmbedStatus',
          component: () => import('@/views/reports/IPOnlyReports'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/app/Index'),
      beforeEnter: authenticate,
      redirect: '/partner-price',
      children: [
        {
          path: '/',
          component: () => import('@/views/app/private/Index'),
          redirect: '/partner-price',
          children: [
            {
              path: 'partner-price',
              name: 'PartnerPrice',
              component: () =>
                import('@/views/search/PartnerPrice'),
              meta: {
                breadcrumb: [
                  { name: 'Partner Price' },
                ],
                nameKey: 'partner',
              },
            },
            {
              path: 'dashboard',
              name: 'Dashboard',
              component: () =>
                import('@/views/Dashboard'),
              meta: {
                breadcrumb: [
                  { name: 'Dashboard' },
                ],
                nameKey: 'dashboard',
              },
            },
            {
              path: 'quotes',
              name: 'Quotes',
              component: () =>
                import('@/views/stokab/Quotes'),
              meta: {
                breadcrumb: [
                  { name: 'Quotes' },
                ],
                nameKey: 'quotes',
              },
            },
            {
              path: 'manual-offer',
              name: 'ManualOffer',
              component: () =>
                import('@/views/manual-offer/ManualOffer'),
              meta: {
                breadcrumb: [
                  { name: 'ManualOffer' },
                ],
                nameKey: 'manualOffer',
              },
            },
            {
              path: 'orders',
              name: 'Orders',
              component: () =>
                import('@/views/order/Orders'),
              meta: {
                breadcrumb: [
                  { name: 'Orders' },
                ],
                nameKey: 'orders',
              },
            },
            {
              path: 'order-price',
              name: 'Order',
              component: () =>
                import('@/views/order/Order'),
              meta: {
                breadcrumb: [
                  { name: 'Order' },
                ],
                nameKey: 'order',
              },
            },
            {
              path: 'order-price/:orderId',
              name: 'OrderApprove',
              component: () =>
                import('@/views/order/Order'),
              meta: {
                breadcrumb: [
                  { name: 'Order' },
                ],
                nameKey: 'order',
                roles: ['admin'],
              },
            },
            {
              path: 'stokab',
              name: 'SearchStokab',
              component: () =>
                import('@/views/search/SearchStokab'),
              meta: {
                breadcrumb: [
                  { name: 'SearchStokab' },
                ],
                nameKey: 'stokab',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'sollentuna',
              name: 'SearchSollentuna',
              component: () =>
                import('@/views/search/SearchSollentuna'),
              meta: {
                breadcrumb: [
                  { name: 'SearchSollentuna' },
                ],
                nameKey: 'sollentuna',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'openinfra',
              name: 'SearchOpeninfra',
              component: () =>
                import('@/views/search/SearchOpeninfra'),
              meta: {
                breadcrumb: [
                  { name: 'SearchOpeninfra' },
                ],
                nameKey: 'openinfra',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'openuniverse',
              name: 'SearchOpenuniverse',
              component: () =>
                import('@/views/search/SearchOpenuniverse'),
              meta: {
                breadcrumb: [
                  { name: 'SearchOpenuniverse' },
                ],
                nameKey: 'openuniverse',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'ip-only',
              name: 'SearchIPOnly',
              component: () =>
                import('@/views/search/SearchIPOnly'),
              meta: {
                breadcrumb: [
                  { name: 'SearchIPOnly' },
                ],
                nameKey: 'ipOnly',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'zitius',
              name: 'SearchZitius',
              component: () =>
                import('@/views/search/SearchZitius'),
              meta: {
                breadcrumb: [
                  { name: 'SearchZitius' },
                ],
                nameKey: 'zitius',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'manage-domains',
              name: 'ManageDomains',
              component: () =>
                import('@/views/external-service/ManageDomains'),
              meta: {
                breadcrumb: [
                  { name: 'ManageDomains' },
                ],
                nameKey: 'manageDomains',
                roles: ['admin'],
              },
            },
            {
              path: 'consumptions',
              name: 'Consumptions',
              component: () =>
                import('@/views/consumption/Consumptions'),
              meta: {
                breadcrumb: [
                  { name: 'Consumptions' },
                ],
                nameKey: 'consumptions',
                roles: ['admin'],
              },
            },
            {
              path: 'consumption-devices',
              name: 'ConsumptionDevices',
              component: () =>
                import('@/views/consumption/ConsumptionDevices'),
              meta: {
                breadcrumb: [
                  { name: 'Consumption Devices' },
                ],
                nameKey: 'consumptionDevices',
                roles: ['admin'],
              },
            },
            {
              path: 'request-price-list',
              name: 'RequestPriceList',
              component: () =>
                import('@/views/stokab/RequestPrices'),
              meta: {
                breadcrumb: [
                  { name: 'RequestPrices' },
                ],
                nameKey: 'requestPriceList',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'reply-request',
              name: 'ReplyRequestPrice',
              component: () =>
                import('@/views/stokab/ReplyRequestPrice'),
              meta: {
                breadcrumb: [
                  { name: 'Request Prices' },
                  { name: 'Reply Request Price' },
                ],
                nameKey: 'replyRequestPrice',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'searched-address',
              name: 'SearchedAddress',
              component: () =>
                import('@/views/search/SearchedAddress'),
              meta: {
                breadcrumb: [
                  { name: 'SearchedAddress' },
                ],
                nameKey: 'searchedAddress',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'update-accesses',
              name: 'UpdateAccess',
              component: () =>
                import('@/views/admin/UpdateAccess'),
              meta: {
                breadcrumb: [
                  { name: 'UpdateAccess' },
                ],
                nameKey: 'accesses',
                roles: ['admin'],
              },
            },
            {
              path: 'products',
              name: 'Products',
              component: () =>
                import('@/views/network/Products'),
              meta: {
                breadcrumb: [
                  { name: 'Products' },
                ],
                nameKey: 'products',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'product',
              name: 'AddProduct',
              component: () =>
                import('@/views/network/ManageProduct'),
              meta: {
                breadcrumb: [
                  { name: 'Manage Product' },
                ],
                nameKey: 'addProduct',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'product/:productId',
              name: 'UpdateProduct',
              component: () =>
                import('@/views/network/ManageProduct'),
              meta: {
                breadcrumb: [
                  { name: 'Manage Product' },
                ],
                nameKey: 'updateProduct',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'add-ons',
              name: 'AddOns',
              component: () =>
                import('@/views/network/AddOns'),
              meta: {
                breadcrumb: [
                  { name: 'AddOns' },
                ],
                nameKey: 'addOns',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'users',
              name: 'Users',
              component: () =>
                import('@/views/admin/Users'),
              meta: {
                breadcrumb: [
                  { name: 'Users' },
                ],
                nameKey: 'users',
                roles: ['admin', 'partnerAdmin'],
              },
            },
            {
              path: 'partners',
              name: 'Partners',
              component: () =>
                import('@/views/admin/Partners'),
              meta: {
                breadcrumb: [
                  { name: 'Partners' },
                ],
                nameKey: 'partners',
                roles: ['admin'],
              },
            },
            {
              path: 'update-company-info',
              name: 'UpdateCompanyInfo',
              component: () =>
                import('@/views/partner/UpdateCompanyInfo'),
              meta: {
                breadcrumb: [
                  { name: 'UpdateCompanyInfo' },
                ],
                nameKey: 'updateCompanyInfo',
                roles: ['partnerAdmin'],
              },
            },
            {
              path: 'profile',
              name: 'Profile',
              component: () =>
                import('@/views/user/Profile'),
              meta: {
                breadcrumb: [
                  { name: 'Profile' },
                ],
                nameKey: 'profile',
              },
            },
            {
              path: 'ip-only-orders',
              name: 'IpOnlyOrders',
              component: () =>
                import('@/views/order/IpOnlyWebOrders'),
              meta: {
                breadcrumb: [
                  { name: 'IP-Only Orders' },
                ],
                nameKey: 'ipOnlyOrders',
                roles: ['admin'],
              },
            },
            {
              path: 'zitius-orders',
              name: 'ZitiusOrders',
              component: () =>
                import('@/views/order/ZitiusWebOrders'),
              meta: {
                breadcrumb: [
                  { name: 'Zitius Orders' },
                ],
                nameKey: 'zitiusOrders',
                roles: ['admin'],
              },
            },
            {
              path: 'parked-zitius-orders',
              name: 'ParkedZitiusOrders',
              component: () =>
                import('@/views/order/ParkedZitiusWebOrders'),
              meta: {
                breadcrumb: [
                  { name: 'Parked Zitius Orders' },
                ],
                nameKey: 'parkedZitiusOrders',
                roles: ['admin'],
              },
            },
            {
              path: 'subscriptions',
              name: 'Subscriptions',
              component: () =>
                import('@/views/order/Subscriptions'),
              meta: {
                breadcrumb: [
                  { name: 'Subscriptions' },
                ],
                nameKey: 'subscriptions',
                roles: ['admin'],
              },
            },
            {
              path: 'ip-only-order/:orderId',
              name: 'IpOnlyOrder',
              component: () =>
                import('@/views/order/IpOnlyWebOrder'),
              meta: {
                breadcrumb: [
                  { name: 'IpOnlyOrder' },
                ],
                nameKey: 'ipOnlyOrder',
                roles: ['admin'],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/:language',
      component: () => import('@/views/app/public/Index'),
      redirect: { name: 'PublicSearch' },
      children: [{
        path: 'search',
        name: 'PublicSearch',
        component: () => import('@/views/search/PublicSearch'),
      }],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  // setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  store.dispatch('changeThemeLoadingState', false)
})

export default router
