<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',

    metaInfo () {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Servercentralen',
        // all titles will be injected into this template
        titleTemplate:
          '%s | Servercentralen',
      }
    },

    created () {
      const mq = window.matchMedia('(prefers-color-scheme: dark)')
      mq.addEventListener('change', e => {
        this.$vuetify.theme.dark = e.matches
      })
    },
  }
</script>
