import { sendGet, sendPost, sendPut } from '../../utils/api'

const state = {}

const actions = {
  invitiePartner ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/partner/invite', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchInviteInfo ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/partner/invite', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  registerPartner ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPost('/partner/register', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchPartners ({ commit }, { offset, limit }) {
    return new Promise((resolve, reject) => {
      sendGet('/partner/list', { offset, limit })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updatePartner ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendPut('/partner', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
