import Vuex from 'vuex'
import Vue from 'vue'

import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

import openNetwork from './modules/openNetwork'
import addOn from './modules/addOn'
import address from './modules/address'
import manualOffer from './modules/manualOffer'
import partner from './modules/partner'
import price from './modules/price'
import product from './modules/product'
import stokab from './modules/stokab'
import user from './modules/user'
import zitius from './modules/zitius'
import company from './modules/company'
import externalDomain from './modules/externalDomain'
import consumption from './modules/consumption'

import themeConfig from './modules/themeConfig'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  state: {
    loading: false,
    loadingText: '',
  },
  actions,
  getters,
  mutations,
  modules: {
    openNetwork,
    addOn,
    address,
    manualOffer,
    partner,
    price,
    product,
    stokab,
    user,
    zitius,
    company,
    externalDomain,
    consumption,

    themeConfig,
  },
})
