import axios from 'axios'
import util from '@/utils'

const baseUrl = util.apiURL

const config = {
  address: baseUrl,
  port: '',
  basePath: '',
  axiosConfig: {
    baseURL: baseUrl,
    headers: {
      // 'Servercentralen-Api-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJsb2NhbGhvc3Q6ODA4MiIsImlhdCI6MTcwMzEyOTkyOH0.QVFdDuE2QGlerlTBeoFxUaHwx_m08p6KcRk9jd31HWM',
    },
    withCredentials: false,
    crossDomain: true,
  },
  getEndpointUrl () {
    return this.address + (this.basePath ? this.basePath : '')
  },
}

if (
  localStorage.getItem('access_token') ||
  sessionStorage.getItem('access_token')
) {
  config.axiosConfig.headers.authorization =
    'Bearer ' +
    (localStorage.getItem('access_token') ||
      sessionStorage.getItem('access_token'))
}

const $http = axios.create(config.axiosConfig)

if (
  localStorage.getItem('access_token') ||
  sessionStorage.getItem('access_token')
) {
  $http.defaults.headers.common.authorization =
    'Bearer ' +
    (localStorage.getItem('access_token') ||
      sessionStorage.getItem('access_token'))
}

const sendPost = (url, payload, headers = null) => {
  return new Promise((resolve, reject) => {
    $http
      .post(url, payload, headers)
      .then((handleSuccess) => {
        resolve(handleSuccess.data)
      })
      .catch((handleError) => {
        reject(handleError)
      })
  })
}

const sendPut = (url, payload, headers = null) => {
  return new Promise((resolve, reject) => {
    $http
      .put(url, payload, headers)
      .then((handleSuccess) => {
        resolve(handleSuccess.data)
      })
      .catch((handleError) => {
        reject(handleError)
      })
  })
}

const sendGet = (url, payload) => {
  return new Promise((resolve, reject) => {
    $http
      .get(url, { params: payload })
      .then((handleSuccess) => {
        resolve(handleSuccess.data)
      })
      .catch((handleError) => {
        reject(handleError)
      })
  })
}

const sendDelete = (url, payload) => {
  return new Promise((resolve, reject) => {
    $http
      .delete(url, { data: payload })
      .then((handleSuccess) => {
        resolve(handleSuccess.data)
      })
      .catch((handleError) => {
        reject(handleError)
      })
  })
}

export default config

export { config }

export { sendPost }

export { sendPut }

export { sendGet }

export { sendDelete }
