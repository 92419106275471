import { sendGet } from '../../utils/api'

const state = {}

const actions = {
  searchCompanies ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/company/search', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getSingatoryOrganization ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sendGet('/company/signatory', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {}

const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations,
}
